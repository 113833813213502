import { ApolloClient, HttpLink } from '@apollo/client/core'
import { ApolloLink } from '@apollo/client/link/core'
import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev'

import { createCache } from '../gqlCache'
import { getApiPath } from '../httpClient'
import currentUserQuery from './queries/user/currentUser.query'

if (import.meta.env.MODE === 'development') {
  loadDevMessages()
  loadErrorMessages()
}

export let csrfToken = ''

export let graphqlEndpointUrl = getApiPath(`/graphql`).toString()

export function createGraphqlClient() {
  let csrfLink = new ApolloLink((operation, forward) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    operation.setContext(({ headers }: any) => {
      return {
        headers: {
          ...headers,
          csrf: csrfToken,
        },
      }
    })

    return forward(operation)
  })

  // let errorLink = onError(({ networkError, ...error }) => {
  //   // TODO: log this in a service like Datadog or Sentry
  //   // console.error({ networkError, error })

  //   if ((<ServerError>networkError)?.statusCode === 401) {
  //     // console.log('unauthorized')
  //     // logOut({ client: graphqlClient, skipMutation: true })
  //   }
  // })

  let graphqlClient = new ApolloClient({
    link: ApolloLink.from([
      // errorLink,
      csrfLink,
      new HttpLink({ uri: graphqlEndpointUrl }),
    ]),
    cache: createCache(),
  })

  graphqlClient
    .watchQuery({
      query: currentUserQuery,
      fetchPolicy: 'cache-only',
    })
    .subscribe({
      next: ({ data }) => {
        let newCsrfToken = data.currentUser?.csrfToken
        if (newCsrfToken !== csrfToken) {
          csrfToken = newCsrfToken
        }
      },
    })

  return graphqlClient
}
