import { InMemoryCache } from '@apollo/client/core'

import { relayStylePagination } from './graphql/policies/pagination'

export function createCache() {
  const defaultRelayStylePagination = relayStylePagination()

  let cache = new InMemoryCache({
    possibleTypes: { Profile: ['Artist', 'Venue', 'Promoter', 'User'] },
    typePolicies: {
      Query: {
        fields: {
          getLatestEvents: relayStylePagination(['cityId']),
          getEventsByIds: defaultRelayStylePagination,
          getEventsByArtistId: relayStylePagination(['artistId']),
          getEventsByVenueId: relayStylePagination(['venueId']),
          getEventsByPromoterId: relayStylePagination(['promoterId']),
          getEventsByGenreIds: relayStylePagination(['genreIds']),
          getSavedEvents: defaultRelayStylePagination,
          getRandomEvents: defaultRelayStylePagination,
          getPromotersByName: relayStylePagination(['name']),
          getArtistsByName: relayStylePagination(['name']),
          getArtistsForArtistFeed: relayStylePagination(['cityId']),
          getVenuesByName: relayStylePagination(['name']),
          getVenuesForVenueFeed: relayStylePagination(['cityId']),
          getMyOrders: defaultRelayStylePagination,
        },
      },
    },
  })

  if (typeof window !== 'undefined' && window.__APOLLO_STATE__) {
    cache = cache.restore(window.__APOLLO_STATE__)
  }

  return cache
}
